<template>
	<form @submit.prevent="handleSubmit(!v$.$invalid)" class="p-grid">
		<Toast />
		<ConfirmDialog></ConfirmDialog>
		
		<div class="grid p-fluid stickBar">
			<div class="col-12">
				<div class="card card-w-title" id="stickBar">
					<Menubar :model="nestedMenuitems">
						<template #end>
							<div class="flex justify-content-end flex-wrap card-container green-container">
								<div class="flex align-items-center justify-content-center font-bold text-gray-500 text-xl m-2">
									{{ mainData.yedekParcaKodu }}
								</div>
								<div class="flex align-items-center justify-content-center m-2">
									<Button class="p-button-rounded p-button-danger mr-0 mb-0" v-if="$router.options.history.state.back != null" icon="pi pi-times" @click="$router.go(-1)"/>
								</div>
							</div>
						</template>
					</Menubar>
				</div>
			</div>
		</div>

		<div class="grid">
			<div class="col-12">
				<div class="card">
					<EntityHeader label="YEDEK PARÇA" entityName="bm_yedekparca"></EntityHeader>
					<div class="grid">
						<div class="col-3">
							<div class="field p-fluid">
								<label for="yedekParcaKodu">Yedek Parça Kodu <span style="color:red">*</span></label>
								<InputText id="yedekParcaKodu" type="text" v-model="mainData.yedekParcaKodu" :disabled="isDisabled" />
								<span v-if="v$.mainData.yedekParcaKodu.$error && submitted">
									<span id="email-error" v-for="(error, index) of v$.mainData.yedekParcaKodu.$errors" :key="index">
										<small class="p-error">{{error.$message}}</small>
									</span>
								</span>
							</div>
						</div>
						<div class="col-3">
							<div class="field p-fluid">
								<label for="yedekParcaAdi">Yedek Parça Adı <span style="color:red">*</span></label>
								<InputText id="yedekParcaAdi" type="text" v-model="mainData.yedekParcaAdi" :disabled="isDisabled" />
								<span v-if="v$.mainData.yedekParcaAdi.$error && submitted">
									<span id="email-error" v-for="(error, index) of v$.mainData.yedekParcaAdi.$errors" :key="index">
										<small class="p-error">{{error.$message}}</small>
									</span>
								</span>
							</div>
						</div>
						<div class="col-3">
							<div class="field p-fluid">
								<label for="yedekParcaAdiEn">Yedek Parça Adı (En)</label>
								<InputText id="yedekParcaAdiEn" type="text" v-model="mainData.yedekParcaAdiEn" :disabled="isDisabled" />
							</div>
						</div>
						<div class="col-3">
							<div class="field p-fluid">
								<label for="urunTipi">Ürün Tipi</label>
								<Dropdown id="urunTipi" v-model="mainData.urunTipi" :options="SM_urunTipi" optionLabel="Value" optionValue="AttributeValue" placeholder="Ürün Tipi" :showClear="true" :disabled="isDisabled" />
							</div>
						</div>
						
						<div class="col-3">
							<div class="field p-fluid">
								<label for="varsayilanFiyat">Varsayılan Fiyat</label>
								<InputNumber id="varsayilanFiyat" v-model="mainData.varsayilanFiyat" mode="decimal" locale="tr-TR" :minFractionDigits="2" :disabled="isDisabled" />
							</div>
						</div>
						<div class="col-3">
							<div class="field p-fluid">
								<EntityLookup id="paraBirimiYedekParca" v-model="mainData.paraBirimiYedekParcaName" ref="entity_paraBirimiYedekParca"
									label="Para Birimi" entityName="transactioncurrency" nameField="currencyname" :state="true" :disabled="isDisabled"
									@itemSelected="paraBirimiYedekParcaSelected = $event" @itemCleared="paraBirimiYedekParcaSelected = null">
								</EntityLookup>
							</div>
						</div>
						<div class="col-3">
							<div class="field p-fluid">
								<label for="uretici">Üretici</label>
								<InputText id="uretici" type="text" v-model="mainData.uretici" :disabled="isDisabled" />
							</div>
						</div>
						<div class="col-3">
							<div class="field p-fluid">
								<label for="urunTuru">Ürün Türü</label>
								<Dropdown id="urunTuru" v-model="mainData.urunTuru" :options="SM_urunTuru" optionLabel="Value" optionValue="AttributeValue" placeholder="Ürün Türü" :showClear="true" :disabled="isDisabled" />
							</div>
						</div>

						<div class="col-3">
							<div class="field p-fluid">
								<label for="kategori">Kategori</label>
								<Dropdown id="kategori" v-model="mainData.kategori" :options="SM_kategori" optionLabel="Value" optionValue="AttributeValue" placeholder="Kategori" :showClear="true" :disabled="isDisabled" />
							</div>
						</div>
						<div class="col-3">
							<div class="field p-fluid">
								<label for="yedekParcaDurumu">Yedek Parça Durumu</label>
								<Dropdown id="yedekParcaDurumu" v-model="mainData.yedekParcaDurumu" :options="SM_yedekParcaDurumu" optionLabel="Value" optionValue="AttributeValue" placeholder="Yedek Parça Durumu" :showClear="true" :disabled="isDisabled" />
							</div>
						</div>
						<div class="col-3">
							<div class="field p-fluid">
								<label for="bulunduguYer">Bulunduğu Yer</label>
								<InputText id="bulunduguYer" type="text" v-model="mainData.bulunduguYer" :disabled="isDisabled" />
							</div>
						</div>
						<div class="col-12">
							<div class="field p-fluid">
								<label for="firmaadi">Açıklama</label>
								<Textarea id="firmaadi" rows="3" :autoResize="false" v-model="mainData.aciklama" :disabled="isDisabled" />
							</div>
						</div>
					</div>
				</div>
			</div>

			<div class="col-12">
				<div class="field p-fluid">
					<UploadPhoto :key="fileName_key" logicalName="bm_yedekparca" :objectId="entityId" :fileName="mainData.fileName"></UploadPhoto>
				</div>
			</div>

			<div class="col-12">
				<div class="card">
					<div class="flex align-items-center">
						<div class="mr-3" style="height:39px">
							<h5 style="line-height: 39px;">YEDEK PARÇA TEKLİF SİPARİŞ ÜRÜNLERİ</h5>
						</div>
					</div>

					<CrmDataTable :key="CrmDataTable_bm_yedekparcateklifsiparisurunleri_key" baseEntityName="bm_yedekparcateklifsiparisurunleri" :options="CrmDataTable_bm_yedekparcateklifsiparisurunleri_options" :rows="20" :isHeaderVisible="false" @onRowSelect="onRowSelect_bm_yedekparcateklifsiparisurunleri" :isSubGrid="true"  />
				</div>
			</div>
		</div>

		<Dialog v-model:visible="displaySiparisUrun" :breakpoints="{'960px': '90vw', '640px': '90vw'}" :style="{width: '90vw'}" header="Ürün Düzenle" :modal="true" :closable="true" :showHeader="true" :draggable="true" @show="showUrunDuzenle">
			<br>
			<div class="p-4">
				<div class="grid">
                    <div class="col-3">
                        <div class="field p-fluid">
                            <label for="siraNo">Sıra</label>
                            <InputText id="siraNo" type="text" v-model="dataSiparisUrun.siraNo" :disabled="isDisabled" />
                        </div>
                    </div>

                    <div class="col-3">
                        <div class="field p-fluid">
                            <label for="yedekParcaKodu">Yedek Parça Kodu</label>
                            <InputText id="yedekParcaKodu" type="text" v-model="dataSiparisUrun.yedekParcaKodu" :disabled="isDisabled" />
                        </div>
                    </div>

                    <div class="col-3">
                        <div class="field p-fluid">
                            <EntityLookupPopup id="yedekParca" v-model="dataSiparisUrun.yedekParcaName" ref="entity_yedekParca"
                                label="Yedek Parça" entityName="bm_yedekparca" nameField="bm_yedekparcaadi" :state="true" :disabled="isDisabled"
                                @itemSelected="yedekParcaSelected = $event" @itemCleared="yedekParcaSelected = null"
                                :CrmDataTable_options="yedekParca_CrmDataTable_options">
                            </EntityLookupPopup>
                        </div>
                    </div>

                    <div class="col-3">
                        <div class="field p-fluid">
                            <label for="teslimSuresi">Teslim Süresi</label>
                            <InputText id="teslimSuresi" type="text" v-model="dataSiparisUrun.teslimSuresi" :disabled="isDisabled" />
                        </div>
                    </div>

                    <div class="col-3">
                        <div class="field p-fluid">
                            <label for="tahminiTeslimEdilmeTarihi">Teslim Edilme Tarihi</label>
                            <Calendar showButtonBar id="tahminiTeslimEdilmeTarihi" v-model="dataSiparisUrun.tahminiTeslimEdilmeTarihi" autocomplete="off" :showIcon="true" :showTime="false" :disabled="isDisabled" />
                        </div>
                    </div>
                    
                    
                    <div class="col-3">
                        <div class="field p-fluid">
                            <label for="birimFiyat">Birim Fiyat</label>
                            <InputNumber id="indirimToplami" v-model="dataSiparisUrun.birimFiyat" mode="decimal" locale="tr-TR" :minFractionDigits="2" @input="changeBirimFiyat" :disabled="isDisabled" />
                        </div>
                    </div>

                    <div class="col-3">
                        <div class="field p-fluid">
                            <label for="adet">Adet</label>
                            <InputNumber id="adet" v-model="dataSiparisUrun.adet" mode="decimal" showButtons :min="0" :max="1000" @input="changeAdet" :disabled="isDisabled" />
                        </div>
                    </div>
                    
                    <div class="col-3">
                        <div class="field p-fluid">
                            <label for="toplamFiyat">Toplam Fiyat</label>
                            <InputNumber id="toplamFiyat" v-model="dataSiparisUrun.toplamFiyat" mode="decimal" locale="tr-TR" :minFractionDigits="2" disabled />
                        </div>
                    </div>

					<div class="col-3">
						<div class="field p-fluid">
							<EntityLookup id="teklifSiparis" v-model="dataSiparisUrun.teklifSiparisName" ref="entity_teklifSiparis"
								label="Teklif Sipariş" entityName="bm_yedekparcateklifsiparis" nameField="bm_teklifbasligi" :state="true"
								@itemSelected="teklifSiparisSelected = $event" @itemCleared="teklifSiparisSelected = null">
							</EntityLookup>
						</div>
					</div>
                </div>

				<Button label="Kaydet" class="mt-3 p-button-secondary" icon="pi pi-save" @click="OnSaveTeklifSiparisUrun" :disabled="isDisabled" />
			</div>
				
		</Dialog>
		
	</form>

	
</template>

<script>
import CrmService from "../service/CrmService";
import { useVuelidate } from "@vuelidate/core";
import { helpers, required } from "@vuelidate/validators";

export default {
	setup: () => ({ v$: useVuelidate() }),
	data() {
		return {
			crmService: null,
			submitted: false,
			OBJECT_TYPE_CODE: 10010,
			mainData: {},
			fileName_key: 0,
			nestedMenuitems: [
				{
					label:'Kaydet',
					icon:'pi pi-fw pi-save',
					command: () => { this.OnSave(false); },
					visible: () => !this.isDisabled
				},
				{
					label: 'Kaydet & Kapat',
					icon: 'pi pi-fw pi-save',
					command: () => { this.OnSave(true); },
					visible: () => !this.isDisabled
				},
				{
					label:'Devre Dışı Bırak',
					icon:'pi pi-fw pi-ban',
					command: () => { this.OnDevreDisiBirak(); },
					visible: () => !this.isDisabled
				},
				{
                    label: "Sil",
                    icon: "pi pi-fw pi-trash",
                    command: () => { this.OnDelete(); },
					visible: () => this.isDeleted
                },
			],

			displaySiparisUrun: false,
            dataSiparisUrun: {},

			CrmDataTable_bm_yedekparcateklifsiparisurunleri_key: 0,
			CrmDataTable_bm_yedekparcateklifsiparisurunleri_options: {
				searchAttributes: ["bm_teklifbasligi", "bm_teklifno", "bm_siparisno", "bm_musterigercekidname", "bm_urunidname", "bm_uniteidname"],
				forceSavedQueryId: '8DB5B62B-81C2-ED11-BC97-000C2920F782',
				filterParent: [
					{ 'name': 'bm_yedekparcaid', 'filterOperator': 'eq', 'value': this.entityId },
				],
			}
		}
	},
	async created() {
		this.crmService = new CrmService();
		console.log('created');
		window.addEventListener('scroll', this.handleScroll);

		let loader = this.$loading.show({
			container: this.$refs.smsContainer
		});

        debugger;
		if (this.entityId != null) {
			try {
				debugger;
				let data = await this.crmService.getYedekParcaById(this.entityId);
				if (data.yetkiVarmi == false) {
					this.$router.replace({ name: 'accessdenied' });
				}
				
				if (data.jsonData.length > 0) {
					this.mainData = data.jsonData[0];
					this.mainData["entityId"] = this.entityId;

					if (this.mainData["paraBirimiYedekParcaId"]) {
						this.$refs.entity_paraBirimiYedekParca.setDefaultData({ "Value": this.mainData["paraBirimiYedekParcaId"], "Name": this.mainData["paraBirimiYedekParcaName"] });
					}

					this.CrmDataTable_bm_yedekparcateklifsiparisurunleri_options.filterParent[0].value = this.entityId;
					this.CrmDataTable_bm_yedekparcateklifsiparisurunleri_key++;

					this.fileName_key++;
				}
			} catch (error) {
				console.log(error);
				//this.$router.push('/');
			}
			finally {
				loader.hide();
			}
		}
		else {
			loader.hide();
		}
    },
	computed: {
		entityId() {
			debugger;
			return this.$route.params.id;
		},
		profileData(){
			return this.$store.getters.getProfile;
		},
		isDisabled: function() {
			if (this.profileData) {
				if (this.profileData.moduller) {
					const filtered = this.profileData.moduller.filter(x => x.name == 'Yedek Parçalar');
					if (filtered.length > 0) {
						return filtered[0].isWrite == false;
					}
				}
			}

			return true;
		},
		isDeleted: function() {
			if (this.profileData) {
				if (this.profileData.moduller) {
					const filtered = this.profileData.moduller.filter(x => x.name == 'Yedek Parçalar');
					if (filtered.length > 0) {
						return filtered[0].isDelete == true;
					}
				}
			}

			return false;
		},
		SM_urunTipi: function () {
			return this.$store.getters.getStringMapByEntityId(this.OBJECT_TYPE_CODE, 'bm_uruntipi');
		},
		SM_urunTuru: function () {
			return this.$store.getters.getStringMapByEntityId(this.OBJECT_TYPE_CODE, 'bm_urunturu');
		},
		SM_kategori: function () {
			return this.$store.getters.getStringMapByEntityId(this.OBJECT_TYPE_CODE, 'bm_kategori');
		},
		SM_yedekParcaDurumu: function () {
			return this.$store.getters.getStringMapByEntityId(this.OBJECT_TYPE_CODE, 'bm_yedekparcadurumu');
		},
	
		paraBirimiYedekParcaSelected: {
			get: function () {
				if (this.mainData["paraBirimiYedekParcaId"]) {
					return { "Value": this.mainData["paraBirimiYedekParcaId"], "Name": this.mainData["paraBirimiYedekParcaName"] }
				} else{ return null; }
			},
			set: function(newValue) {
				if (newValue == null) {
					this.mainData["paraBirimiYedekParcaId"] = null;
					this.mainData["paraBirimiYedekParcaName"] = null;
				}
				else {
					this.mainData["paraBirimiYedekParcaId"] = newValue.Value;
					this.mainData["paraBirimiYedekParcaName"] = newValue.Name;
				}
			}
		},
		teklifSiparisSelected: {
			get: function () {
				if (this.dataSiparisUrun["teklifSiparisId"]) {
					return { "Value": this.dataSiparisUrun["teklifSiparisId"], "Name": this.dataSiparisUrun["teklifSiparisName"] }
				} else{ return null; }
			},
			set: function(newValue) {
				if (newValue == null) {
					this.dataSiparisUrun["teklifSiparisId"] = null;
					this.dataSiparisUrun["teklifSiparisName"] = null;
				}
				else {
					this.dataSiparisUrun["teklifSiparisId"] = newValue.Value;
					this.dataSiparisUrun["teklifSiparisName"] = newValue.Name;
				}
			}
		},
	},
	methods: {
		async OnSave(kapat) {
			debugger;

			this.submitted = true;
			this.v$.$touch();

			if (this.v$.$invalid) {
				this.v$.$errors.forEach(element => {
					this.$toast.add({ severity: 'error', summary: 'Eksik alanlar var.', detail: element.$message, life: 3500 });
				});
			} else {
				try {
					const response = await this.crmService.getYedekParcaUpdate(this.mainData);
					if (response) {
						if (response.hata == true) {
							this.$toast.add({severity:'error', summary: 'Kaydedilemedi', detail: response.hataAciklamasi, life: 5000});
						}else {
							this.$toast.add({severity:'success', summary: 'Bilgi', detail:'Başarıyla Kaydedildi', life: 3000});
							if (window.opener) {
								window.opener.window.postMessage({'tag': 'refresh'}, window.location.origin);
							}
							
							if (kapat) {
								setTimeout(() => {
									window.close();
								}, 1000);
							}
						}
					}
				} catch (error) {
					this.$toast.add({severity:'error', summary: 'Eksik alanlar var.', detail: error.message, life: 3500});
				}
			}
		},
		async OnSaveTeklifSiparisUrun() {
            try {
                const response = await this.crmService.getYedekParcaTeklifSiparisUrunuUpdate(this.dataSiparisUrun);
                debugger;
                if (response) {
                    if (response.hata == true) {
                        this.$toast.add({severity:'error', summary: 'Kaydedilemedi', detail: response.hataAciklamasi, life: 5000});
                    }else {
                        this.$toast.add({severity:'success', summary: 'Bilgi', detail:'Başarıyla Kaydedildi', life: 3000});
                        this.displaySiparisUrun = false;
                        this.CrmDataTable_bm_yedekparcateklifsiparisurunleri_key++;
                    }
                }
            } catch (error) {
                this.$toast.add({severity:'error', summary: 'Eksik alanlar var.', detail: error.message, life: 3500});
            }
        },
		async OnDelete(){
            this.deleteItem('bm_yedekparca', this.entityId);
        },
		OnDevreDisiBirak() {
			const postData = {
				logicalName: 'bm_yedekparca',
				entiyId: this.entityId,
				stateCode: 1,
				statusCode: 2
			};

			this.devreDisiItem(postData);
		},
		onRowSelect_bm_yedekparcateklifsiparisurunleri(data){
			console.log(data);
			this.displaySiparisUrun = true;

			this.crmService.GetEntityById({
				"entityName": "bm_yedekparcateklifsiparisurunleri",
				"entityId": data.entityId,
				"columnNames": ["bm_parcano", "bm_yedekparcakodu", "bm_yedekparcaid", "bm_adet", "bm_birimfiyat", "bm_teslimsuresi", "bm_toplamfiyat", "bm_tahminiteslimedilmetarihi", "bm_yedekpacrateklifsiparisurunleriid" ]})
			.then(datas => {
				if (datas.jsonData != null) {
					debugger;
					console.table(datas.jsonData);
					this.dataSiparisUrun = {
						entityId: datas.jsonData["entityId"],
						siraNo: datas.jsonData["bm_parcano"],
						yedekParcaKodu: datas.jsonData["bm_yedekparcakodu"],
						yedekParcaName: datas.jsonData["bm_yedekparcaidName"],
						yedekParcaId: datas.jsonData["bm_yedekparcaid"],
						teslimSuresi: datas.jsonData["bm_teslimsuresi"],
						tahminiTeslimEdilmeTarihi: null,
						birimFiyat: parseFloat(datas.jsonData["bm_birimfiyat"].replaceAll(".","").replaceAll(",",".")),
						adet: parseInt(datas.jsonData["bm_adet"]),
						toplamFiyat: parseFloat(datas.jsonData["bm_toplamfiyat"].replaceAll(".","").replaceAll(",",".")),
						teklifSiparisId: datas.jsonData["bm_yedekpacrateklifsiparisurunleriid"],
					};

					if (datas.jsonData["bm_tahminiteslimedilmetarihi"]) {
						this.dataSiparisUrun.tahminiTeslimEdilmeTarihi = new Date(datas.jsonData["bm_tahminiteslimedilmetarihi"]);
					}

					if (datas.jsonData["bm_yedekpacrateklifsiparisurunleriid"]) {
						this.$refs.entity_teklifSiparis.setDefaultData({ "Value": datas.jsonData["bm_yedekpacrateklifsiparisurunleriid"], "Name": datas.jsonData["bm_yedekpacrateklifsiparisurunleriidName"] });
					}

					this.$refs.entity_yedekParca.setDefaultData({ "Value": this.dataSiparisUrun["yedekParcaId"], "Name": this.dataSiparisUrun["yedekParcaName"] });
				}
			})
			.catch(error => console.log(error));
		},
		changeAdet(event){
            this.dataSiparisUrun.toplamFiyat = this.dataSiparisUrun.birimFiyat * event.value;
        },
        changeBirimFiyat(event){
            this.dataSiparisUrun.toplamFiyat = this.dataSiparisUrun.adet * event.value;
        },
		convertToDateTime(stringValue){
			const dateParts = stringValue.split("."); // yıl, ay ve gün değerlerini ayırır
			return new Date(dateParts[2], dateParts[1]-1, dateParts[0]); // tarih nesnesini oluşturur
		},
		handleScroll() {
			const el = document.getElementById('stickBar');

			if (window.scrollY > 50) {
				el.style.boxShadow = '0px 2px 1px rgba(0, 0, 0, 0.09), 0px 4px 2px rgba(0, 0, 0, 0.09), 0px 8px 4px rgba(0, 0, 0, 0.09), 0px 16px 8px rgba(0, 0, 0, 0.09), 0px 32px 16px rgba(0, 0, 0, 0.09)';
			}
			else {
				el.style.boxShadow = '';
			}
		},
	},
	watch: {
		profileData(val) {
			if (val) {
				if (val.moduller) {
					const filtered = val.moduller.filter(x => x.name == 'Yedek Parçalar');
					if (filtered.length == 0) {
						this.$router.replace({ name: 'accessdenied' });
					}
				}
			}
		}
	},
	validations() {
		return {
			mainData: {
				yedekParcaKodu: {
					required : helpers.withMessage('Yedek Parça Kodu alanı dolu olmalıdır!', required),
				},
				yedekParcaAdi: {
					required : helpers.withMessage('Yedek Parça Adı alanı dolu olmalıdır!', required),
				},
			},
		}
	}
}
</script>

<style lang="scss" scoped>

</style>
